
























































































































































import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { DataTable, Form } from '../components/admin';

import { ClientFormMixin, UtilsMixin, NotificationMixin } from '../mixins';
import { Component, Mixins } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

@Component({
  name: 'Clients',
  components: {
    Form,
    DataTable,
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('client', ['getList', 'getCount']),
  },
})
export default class Clients extends Mixins(
  ClientFormMixin,
  UtilsMixin,
  NotificationMixin
) {
  selected: any = { dashboardDesign: {}, surveyDesign: {} };
  fields = [
    {
      key: 'name',
      label: 'Client',
      sortable: true,
    },
    {
      key: 'code',
      label: 'Client Code',
      sortable: true,
      tdClass: 'text-uppercase',
    },
    // {
    //  key: 'contactName',
    //  sortable: true
    // },
    // {
    //  key: 'contactTitle'
    // },
    {
      key: 'createdAt',
      label: 'Date Added',
      sortable: true,
      formatter(val) {
        return moment(val).format(process.env.VUE_APP_DATE_FORMAT);
      },
    },
    {
      key: 'liveSurveys',
      sortable: true,
    },
    {
      key: 'weblink',
      label: 'Weblink to Client Dashboard',
      sortable: false,
    },
  ];

  readonly $refs!: {
    addModal: BModal;
    editModal: BModal;
    designModal: BModal;
    deleteModal: BModal;
    table: DataTable;
  };

  fetchData(params) {
    this.$store.dispatch('client/list', { params });
    this.$store.dispatch('client/count', { params });
  }

  addClient() {
    this.selected = {};
    this.$refs.addModal.show();
  }

  editClient(client) {
    this.selected = client;
    const industrySchema: any = _.find(this.schema, { key: 'industry' });
    industrySchema.items = _.filter(this.industries, {
      sector: this.selected.sector,
    });
    this.$refs.editModal.show();
  }

  editClientDesign(client) {
    this.selected = client;
    this.$refs.designModal.show();
  }

  deleteClient(client) {
    this.selected = client;
    this.$refs.deleteModal.show();
  }

  exportClient(client) {
    const params = {
      dl: 1,
    };
    this.$store
      .dispatch('client/get', { id: client.id, params })
      .then((res) => {
        const fileName = `Client-${client.name}.xlsx`;

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, fileName);
        } else {
          // For other browsers, create a link pointing to the ObjectURL containing the blob.
          const objectURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = fileName;
          document.body.appendChild(link); //  Need to do this for Firefox
          link.click();

          setTimeout(function () {
            document.body.removeChild(link); //  Need to do this for Firefox
            window.URL.revokeObjectURL(objectURL);
          }, 100);
        }
      });
  }

  exportClients() {
    const params = {
      perPage: 1000,
    };
    this.notifyInfo('Downloading clients...');
    this.$store
      .dispatch('client/export', { params })
      .then((res) => {
        const fileName = 'Clients.xlsx';

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(res, fileName);
        } else {
          // For other browsers, create a link pointing to the ObjectURL containing the blob.
          const objectURL = window.URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = objectURL;
          link.download = fileName;
          document.body.appendChild(link); //  Need to do this for Firefox
          link.click();

          setTimeout(function () {
            document.body.removeChild(link); //  Need to do this for Firefox
            window.URL.revokeObjectURL(objectURL);
          }, 100);
        }

        this.notifySuccess('Clients downloaded');
      })
      .catch((err) => {
        const errMsg = err.message || 'Failed to download clients';
        this.notifyError(errMsg);
      });
  }

  handleAdd(data) {
    return this.$store
      .dispatch('client/create', { data })
      .then(() => {
        this.$refs.addModal.hide();
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMsg = err.message || 'Failed to add client';
        this.notifyError(errMsg);
      });
  }

  handleEdit(data) {
    const { id } = this.selected;
    return this.$store
      .dispatch('client/update', { id, data })
      .then(() => {
        this.$refs.editModal.hide();
        this.$refs.designModal.hide();
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMsg = err.message || 'Failed to update client';
        this.notifyError(errMsg);
      });
  }

  handleDelete(evt) {
    const { id } = this.selected;
    return this.$store
      .dispatch('client/delete', { id })
      .then(() => {
        this.$refs.deleteModal.hide();
        this.$refs.table.refresh();
      })
      .catch((err) => {
        const errMsg = err.message || 'Failed to delete client';
        this.notifyError(errMsg);
      });
  }

  getWeblink(client) {
    return `${window.location.protocol}//${window.location.host}/${client.slug}/`;
  }

  onCancel() {
    this.selected = { clients: [] };
    this.$refs.addModal.hide();
    this.$refs.editModal.hide();
    this.$refs.designModal.hide();
  }

  copyLink(client) {
    const link = this.getWeblink(client);
    this.copyToClipboard(link).then(() => {
      this.notifySuccess('Copied to clipboard');
    });
  }

  onInputUpdated(data) {
    if (data.sector !== this.selected.sector) {
      const industrySchema: any = _.find(this.schema, { key: 'industry' });
      industrySchema.items = _.filter(this.industries, { sector: data.sector });
      // TODO: refactor this for a more generic handling??
      data.industry = null;
    }

    // TODO: not to reset form all the time & not to assigned all the time
    this.selected = Object.assign({}, this.selected, data); // need to do this since we need id!
  }
}
